.Signup {
}
.Signup h1 {
  margin: 0 10px;
  padding: 20px;
  font-size: 2em;
  border-bottom: 1px solid #ccc;
}
.Signup form {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  width: 30%;
}
.Signup form button {
  margin-top: 20px;
  align-self: center;
}
