.Button {
  padding: 10px;
  background-color: var(--orange-background);
  color: white;
  font: inherit;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  outline: none;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}
.Button:hover {
  background-color: #ca5b00;
}
.Button:disabled {
  cursor: not-allowed;
  color: #ccc;
}
.Button:disabled:hover {
  background-color: inherit;
  cursor: not-allowed;
  color: #ccc;
}
/* .Button:first-of-type {
  margin-left: 0;
  padding-left: 0;
} */

.NoBg {
  background: none;
}
.NoBg:hover {
  background-color: inherit;
}
.Login {
  display: block;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  font-size: 1.1em;
  color: var(--grey-background);
  background-color: var(--teal-background);
}
.Login:hover {
  background-color: inherit;
  box-shadow: 0px 3px 14px -7px var(--teal-background);
  background-color: rgb(3, 195, 195);
}
.Transparent {
  background: none;
  box-sizing: border-box;
  border: 1px solid #ccc;
}
.Transparent:hover {
  color: #000;
  background-color: #ccc;
}
