.Footer {
  position: absolute;
  bottom: 5px;
  color: orange;
  text-align: center;
  font-size: 0.9rem;
  width: 100%;
}
.Footer > * {
  display: inline-block;
  padding: 4px 5px;
}
.Footer button,
.Footer a {
  background: none;
  border: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.Footer a:hover,
.Footer button:hover {
  color: rgb(255, 187, 98);
}

.Terms div {
  color: #fff;
  padding: 20px;
  text-align: start;
}
.Terms h1 {
  font-size: 2rem;
}
.Terms h2 {
  font-size: 1.8rem !important;
  border: none !important;
  padding: 15px 0 !important;
}
.Terms h3 {
  font-size: 1.5rem;
}
.Terms strong {
  font-weight: bold;
}
.Terms * {
  font-size: 1.1rem;
  margin: 10px 0;
  line-height: 2rem;
}
