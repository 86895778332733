.InProgressCard {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  padding: 10px 20px;
}
.InProgressCard span:nth-child(3) {
  margin-left: 10px;
  /* font-style: italic; */
}
.InProgressCard > span:last-of-type {
  margin-right: auto;
}

.Id {
  display: block;
  width: 60px;
  padding: 3px;
  background-color: #555;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: var(--border-radius);
}

.InProgressCard .MemberName {
  display: flex;
  margin-left: 10px;
  padding: 3px;
  font-size: 1.2rem;
  text-align: center;
}
