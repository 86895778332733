.NavigationItem a {
  display: block;
  margin-right: 15px;
  padding: 20px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
.NavigationItem a:hover {
  background-color: rgb(255, 144, 124);
}
.NavigationItem .active {
  font-weight: 600;
  background-color: #f42;
  pointer-events: none;
}

/* Request Feature */
.NavigationItem:nth-last-child(3) {
  position: absolute;
  bottom: 182px;
  width: 100%;
}
/* Request Feature */
.NavigationItem:nth-last-child(2) {
  position: absolute;
  bottom: 126px;
  width: 100%;
}

/* Logout */
.NavigationItem:last-child {
  position: absolute;
  bottom: 70px;
  width: 100%;
}
