.UsefulLinks {
  display: inline-block;
  padding-top: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  font-size: 1rem;
}

.UsefulLinks li {
  margin-top: 10px;
  margin-left: 20px;
}
.UsefulLinks li::before {
  content: '\2022';
  color: var(--orange-background);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -0.5em;
}
.UsefulLinks a {
  color: var(--orange-background);
  text-decoration: none;
  border-bottom: 1px dotted var(--orange-background);
}
