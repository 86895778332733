.Logo {
  display: flex;
  align-items: center;
  /* position: absolute; */
  /* left: 0; */
  /* right: 0; */
  /* margin: 0 auto; */
  padding: 7px;
  padding-left: 20px;
  color: #fff;
  font-family: 'Play', sans-serif;
  font-weight: bold;
  /* height: 100%; */
  text-align: center;
}
.Logo h1 {
  font-size: 2em;
}
.Logo h1 span {
  font-size: 1.5rem;
  color: #ccc;
  font-weight: normal;
  background: -webkit-linear-gradient(
    45deg,
    var(--orange-background),
    var(--teal-background)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 768px) {
  .Logo {
    width: 100%;
  }
  .Logo h1 {
    margin: 0 auto;
  }
  .Logo h1 span {
    display: none;
  }
}

.Logo img {
  height: 100%;
  border-radius: 8px;
}
