.SideSlider {
  position: absolute;
  height: 100%;
  width: 60px;
  background: var(--grey-background);
  color: #fff;
  font-size: 24px;
  transform: translateX(-100%);
  transition: all 0.5s ease-in-out;
  transition-delay: 1s;
  z-index: 200;
}
.SideSlider section {
  max-height: 90%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  border-bottom: 1px solid #777;
}
.SideSlider section::-webkit-scrollbar {
  display: none;
}
.SideSlider span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 2px solid #777;
  margin: 10px auto;
  border-radius: 100%;
  cursor: pointer;
  background-color: var(--grey-background);
}
.SideSlider span:hover {
  background-color: #555;
}
.SideSlider a {
  text-align: center;
  height: 100%;
  width: 100%;
  padding-top: 13px; /* (50px - 4px) / 2*/
  color: inherit;
  font-size: 20px;
}

.SideSlider .active {
  background-color: #f42;
  pointer-events: none;
}

@media screen and (min-width: 769px) {
  .SideSlider::before {
    position: absolute;
    content: '\276F';
    text-align: end;
    line-height: 190px;
    padding-right: 5px;
    height: 100%;
    width: 20px;
    right: -20px;
    background-color: transparent;
    top: calc(50% - 95px);
    left: 20px;
    height: 190px;
    width: 60px;
    border-radius: 40%;
    background: inherit;
    transition: left ease 0.3s;
    transition-delay: 1s;
    z-index: -1;
  }
  .SideSlider:hover:before {
    content: '';
    left: -60px;
    transition-delay: 0.2s;
  }
  .SideSlider:hover {
    transform: translateX(0);
    transition-delay: 0s;
    -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
  }
}
