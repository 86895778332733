.Member {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
  border-radius: var(--border-radius);

  -webkit-box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.75);
}
.Member:hover {
  -webkit-box-shadow: 0px 5px 15px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 15px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 15px -4px rgba(0, 0, 0, 0.75);
}
.Red {
  background-color: rgba(179, 56, 34, 0.2);
}
.Yellow {
  background-color: rgba(255, 174, 0, 0.2);
}
.Green {
  background-color: rgba(34, 179, 41, 0.2);
}
.Member aside {
  justify-self: end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Member aside span {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 100%;
  cursor: default;
}
.Id {
  display: block;
  width: 60px;
  padding: 3px;
  background-color: #555;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: var(--border-radius);
}

.Member main {
  display: flex;
  flex-direction: column;
}
.Member h3 {
  display: block;
  position: absolute;
  bottom: 20px;
  font-size: 1.5em;
  margin-top: auto;
  white-space: nowrap;
}
.Member section {
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.Member a {
  padding: 10px;
  margin: 25px 0;
  display: block;
  min-width: 115px;
  border: 1px solid #fff;
  border-radius: var(--border-radius);
  color: #fff;
  text-align: center;
  text-decoration: none;
}
.Member a:hover {
  background-color: #fff;
  background-color: rgba(234, 100, 29, 0.5);
  /* color: var(--grey-background); */
}
.Member .AddBtn a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 115px;
  border: 1px solid #fff;
  padding: 0;
}

.Member .AddBtn a span {
  position: relative;
  display: block;
  margin-bottom: 15px;
  width: 40px;
  height: 40px;
  transition: all 1s ease-in-out;
}
.Member .AddBtn a:focus span::before {
  transform: rotate(90deg);
  transition-duration: 0.3s;
}

.Member .AddBtn a span::before {
  position: absolute;
  content: '';
  top: 0;
  left: 17.5px;
  width: 5px;
  height: 40px;
  background-color: #fff;
  border-radius: var(--border-radius);
}
.Member .AddBtn a span::after {
  position: absolute;
  content: '';
  left: 0;
  top: 17.5px;

  width: 40px;
  height: 5px;
  background-color: #fff;
  border-radius: var(--border-radius);
}
