.Faq h1 {
  font-size: 2em;
}

.Faq h2 {
  font-size: 1.5em;
  padding-top: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}
