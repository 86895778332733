.Modal {
  position: fixed;
  z-index: 500;
  background-color: var(--grey-background);
  width: 90%;
  /* max-height: 70%; */
  /* border: 1px solid #ccc; */
  /* padding: 16px; */
  height: 70%;
  left: 5%;
  top: 15%;
  margin-bottom: 40px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  overflow-y: scroll;
}
.NoScroll {
  overflow-y: hidden;
}
/* 
@media (max-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
  }
} */
