.ViewSoup {
  position: relative;
  color: #fff;
  padding: 20px 20px;
}
.ViewSoup .Controls {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
}
.ViewSoup .Controls button {
  margin-left: 10px;
}
.ViewSoup header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px 10px;
  border-top: 5px solid var(--orange-background);

  border-bottom: 5px solid var(--orange-background);
}
.ViewSoup header div:nth-child(2) {
  text-align: end;
}
.ViewSoup header span {
  display: block;
  padding: 5px 0;
}
.ViewSoup table {
  width: 100%;
  margin-top: 30px;
}

.ViewSoup tbody td {
  margin: 15px 0;
}
.ViewSoup thead {
  font-size: 1.2em;
  border-bottom: 1px dotted #ccc;
}
.ViewSoup tbody tr {
  display: grid;
  grid-template-columns: 50% 15% 35%;
  gap: 10px;
  align-items: center;
}
.ViewSoup tbody tr td:nth-child(2) {
  font-style: italic;
  text-align: center;
}
