.TableOfContents {
  margin-top: 20px;
  padding: 10px 0;
  padding-left: 10px;
  color: var(--orange-background);
  border: 1px dashed var(--orange-background);
}
.TableOfContents h3 {
  padding-bottom: 4px;
  font-size: 1.2em;
}
.TableOfContents ul {
  margin-top: 10px;
}
.TableOfContents a {
  color: var(--orange-background);
  cursor: pointer;
}
.TableOfContents li {
  margin-top: 10px;
}
