.SideDrawer {
  position: relative;
  width: 250px;
  height: calc(100vh - 50px);
  background-color: var(--orange-background);
  transition: all ease-in-out 0.3s;
  z-index: 200;
}
.SideDrawer h2 {
  font-size: 1.2em;
  color: #fff;
  padding: 20px;
  border-bottom: 1px solid rgb(255, 123, 0);
}
@media screen and (max-width: 768px) {
  .SideDrawer {
    display: absolute;
    /* transform: translateX(-100%); */
    left: -250px;
  }
  .SideDrawer.Open {
    transform: translateX(0);
    left: 60px;
  }
}
