.AddMember {
  position: relative;
  display: flex;
  justify-content: center;
  width: calc(100% - 40px);
  margin: 20px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.AddMember form {
  display: flex;
  width: 100%;
  z-index: 500;
}
.AddMember form span {
  margin: auto 0;
  margin-left: 5px;
}
.AddMember input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #888;
  color: #fff;
  padding: 5px;
  margin: 0 5px;
}
.AddMember label {
  display: flex;
  align-items: center;
}
/* .AddMember input:first-child {
  width: 30%;
} */
.AddMember input:nth-child(2) {
  width: 10%;
}

.AddMember form button:first-of-type {
  margin-left: auto;
  margin-right: 10px;
}
