.Login {
  display: grid;
  grid-template-columns: 60% auto;
  grid-template-areas: 'history login';
  height: 100%;
  background-color: var(--orange-background);
  background: linear-gradient(135deg, var(--orange-background), #f42);
  overflow-x: hidden;
  overflow-y: scroll;
}

@media screen and (max-width: 768px) {
  .Login {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'history'
      'login';
    height: 100%;
    justify-items: center;
    background-color: var(--orange-background);
    background: linear-gradient(135deg, var(--orange-background), #f42);
  }
}
