.ViewHistory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 280px;
  font-size: 1.4em;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .ViewHistory {
    grid-area: history;
    margin: 20px 10px;
    max-width: 480px;

    z-index: 100;
  }
  .ViewHistory form {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
.ViewHistory h2 {
  /* display: block; */
  padding-bottom: 10px;
  color: #fff;
  font-size: 1.4em;
  font-weight: 600;
}
.ViewHistory form {
  display: flex;
  place-items: center;
  box-shadow: 0px 3px 14px -7px rgba(0, 0, 0, 0.75);
  border-radius: var(--border-radius);
}

.ViewHistory input {
  height: 100%;
  border: 0;
  margin: 0;
  padding-left: 10px;
  font-size: 1em;
  background-color: var(--grey-background);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.ViewHistory div {
  height: 100%;
  padding: 0;
  z-index: 5;
}
.ViewHistory button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: rgb(88, 88, 88);
}
