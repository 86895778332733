.PendingMembers {
  border-bottom: 1px dashed #ccc;
  border-top: 1px dashed #ccc;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.PendingMembers h2 {
  font-size: 1.3em !important;
  border: none !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 20px;
}
