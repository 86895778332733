.Input {
  position: relative;
  width: 100%;
  padding: 10px 0;
  /* grid-area: input; */
}

.Input [data-attr='name-login'] {
  grid-area: name;
}
.Input [data-attr='initial-login'] {
  grid-area: login;
  width: 50%;
}
.Input[data-attr='email-login'] {
  grid-area: email;
}
.Input[data-attr='password-login'] {
  grid-area: password;
}
.Input[data-attr='intern-login'] {
  grid-area: intern;
}
.Label {
  display: block;
  margin-bottom: 4px;
}

.InputElement {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  background: none;
  border-bottom: 1px solid rgb(112, 112, 112);
  color: #fff;
  padding: 6px 4px 4px 4px;
  margin: 5px 0;
}

.InputElement:focus:not([type='checkbox']) + label,
.InputElement:not([value='']) + label {
  transform: translateY(-160%);
  font-size: 0.9em;
  left: 0;
}
.InputElement + label {
  position: absolute;
  bottom: 16px;
  left: 4px;
  transition: 0.2s ease;
  z-index: -1;
}
.Input .Invalid {
  border-bottom: 2px solid red;
  margin-bottom: 4px;
}
.Invalid + label {
  color: red;
}
.InputElement[type='checkbox'] {
  margin: 0;
}
.InputElement[type='checkbox'] + label {
  display: unset;
  position: relative;
}
.InputElement[type='checkbox'].Hide,
.InputElement[type='checkbox'].Hide + label {
  display: none;
}
/* Textarea */
.Textarea {
  max-width: 450px;
  background-color: rgb(42, 42, 42);
  border: none;
  resize: none;
}
/* Radio buttons style */
.Fieldset {
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  margin: 10px 0;
}
.Fieldset h3,
.Input h3 {
  width: 100%;
  font-size: 1.1em;
  margin-bottom: 5px;
  white-space: pre-wrap;
}
.Fieldset input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.Fieldset input[value='NA']:checked ~ span {
  background-color: #777;
}
.Fieldset input:checked ~ span {
  background-color: var(--orange-background);
}

.Fieldset label {
  display: block;
  position: relative;
  height: 40px;
  width: 110px;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid beige;
}

.Fieldset span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.Fieldset.NoteAdded button {
  color: green;
}
.Fieldset button {
  margin: 0 !important;
  /* padding: 0; */
  margin-left: auto !important;
}
