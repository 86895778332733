@keyframes slideInFromLeft {
  0% {
    opacity: 0.5;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.Auth {
  display: flex;
  place-items: center;
  position: relative;
  justify-self: center;
  margin: 0;
  width: 300px;
  z-index: 1;
  animation: slideInFromLeft 0.5s 0s ease-in-out;
  grid-area: login;
}
@media screen and (min-width: 769px) {
  .Auth {
    justify-self: end;
    margin-right: 50px;
  }
  .Auth form {
    width: 100%;
    padding: 0;
  }
  .Auth::before {
    position: absolute;
    content: '';
    top: -50%;
    right: -130%;
    width: 260%;
    height: 200%;
    background-color: rgb(19, 3, 1);
    border-radius: 100%;
    opacity: 0.3;
    z-index: -1;
  }
}

.Auth form {
  position: relative;
  padding: 25px 15px;
  color: #fff;
  z-index: 1;
  border-radius: 10px;
  background-color: var(--grey-background);
  animation: fadein 0.5s ease;
  width: 100%;
  display: grid;
  grid-template-areas:
    'header header'
    'name initial'
    'email email'
    'password password'
    'intern intern'
    'button button'
    'switch switch';
  gap: 10px;
}
.Auth button:first-of-type {
  grid-area: button;
}
.Auth form div {
  padding-bottom: 0;
  margin-bottom: 0;
}
.Auth form label {
  bottom: 5px;
}
.Auth a {
  grid-area: intern;
  color: #fff;
  color: var(--orange-background);
  /* z-index: 10; */
}
.Auth form * {
  margin: 5px 0;
}
.Auth h2 {
  grid-area: header;
  margin-bottom: 15px;
  font-size: 1.3em;
  font-weight: bold;
}
.Auth p {
  grid-area: switch;
  padding: 10px 0;
  text-align: center;
}

.SignupBtn {
  display: block;
  color: var(--orange-background);
  text-decoration: underline;
  cursor: pointer;
}
