.TopicCard {
  display: flex;
  flex-basis: 33.333333%;
  height: 100px;
  min-width: 180px;
  margin: 20px;
  border: 1px solid #ea641d;
  cursor: pointer;
}
.TopicCard a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 1.5em;
  text-decoration: none;
  color: inherit;
}

.TopicCard:hover {
  background-color: #555;
}
