.NewClinic {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.NewClinic form {
  display: flex;
  align-items: center;
  max-width: 300px;
  z-index: 1;
}
.NewClinic form > div {
  margin-right: 20px;
}

.Button {
  height: 135px;
  width: 200px;
  margin: 20px;
  padding: 20px;
  border: 1px solid var(--orange-background);
  line-height: 1.2;
  cursor: pointer;
}
.Button:hover {
  background-color: #555;
}
.Button span {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.ShowNotice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 40px;
}
.ShowNotice h3 {
  font-size: 1.5em;
}
.ShowNotice p {
  margin-top: 20px;
  text-align: center;
  line-height: 1.5em;
}

.ShowNotice p:last-of-type {
  margin-top: auto;
  margin-bottom: 20px;
}

.ShowNotice a {
  color: var(--orange-background);
}
.ShowNotice ul {
  margin-top: 20px;
  list-style: disc;
}
.ShowNotice li {
  margin-top: 8px;
  margin-left: 20px;
}
