.History {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.History h1 {
  font-size: 2em;
}
.History > table {
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;

  -webkit-box-shadow: 0px 6px 17px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 17px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 17px -5px rgba(0, 0, 0, 0.75);
}
.History > table thead {
  font-size: 1.3em;
  /* font-weight: 600; */
  border-bottom: 1px solid #ccc;
}
.History > table td {
  text-align: center;
  padding: 10px;
}

.Alternate {
  background-color: rgb(109, 87, 46);
}

.DeleteSoup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.DeleteSoup div {
  margin: 20px;
}
.DeleteSoup button {
  margin: 0 20px;
  width: 100px;
}
/* 
.History button {
  background: none;
  border: 1px solid #ccc;
} */
