.Reset {
  grid-area: reset;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 20px;
}
.Reset h1 {
  display: block;
  text-align: center;
  padding-top: 40px;
}
.Reset h2 {
  line-height: 1.5em;
}
.Reset form {
  display: flex;
  align-items: flex-end;
  max-width: 350px;
  margin-top: 50px;
  z-index: 1;
}
.Reset form > div {
  margin-right: 10px;
}

.Reset form button {
  white-space: nowrap;
  margin-bottom: 13px;
}
.Reset form label {
  color: #fff;
}
.Reset h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 1.8em;
  color: var(--orange-background);
}
