.FormSection {
  overflow: hidden;
}
.FormSection h2 {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-left: 25px;
  padding-bottom: 2px;
  font-size: 1.4em;
  cursor: pointer;
}
.FormSection h2 span {
  position: absolute;
  margin-right: 5px;
  left: 2px;
  width: 13px;
  height: 13px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(-45deg);
  transition: all 0.15s ease-in-out;
}
.FormSection h2 .Rotate {
  display: inline-block;
  transform: rotate(45deg);
  border-color: red;
}
.Content {
  display: none;
  margin-left: 25px;
}
.Expanded {
  display: block;
}
.FormSection h3 {
  margin-top: 5px;
  margin-bottom: 10px;
}
