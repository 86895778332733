.StaffCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 10px;
  margin: 20px 10px;
  border: 1px solid var(--orange-background);
  border-radius: var(--border-radius);
}
.StaffCard button {
  width: 120px;
  background: none;
  margin: 0 5px;
}
.StaffCard span {
  margin-right: 10px;
}
