.Roster {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
  padding-bottom: 25px;
}
.Roster header {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
}
.Roster > div {
  grid-column: 1 / -1;
}
.Roster i {
  margin-right: 5px;
}
.Roster h1 {
  font-size: 2em;
}
