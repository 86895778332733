.NewPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  color: #fff;
  h2 {
    margin-top: 80px;
    font-size: 1.5em;
    font-weight: 500;
    color: var(--orange-background);
  }
}
.NewPassword form {
  margin-top: 20px;
  margin-bottom: 30px;
  max-width: 350px;
  z-index: 1;
  text-align: center;
  button {
    margin-top: 20px;
  }
}
