.Toolbar {
  grid-column: 1 / -1;
  display: flex;
  position: relative;
  align-items: center;
  height: 50px;
  background-color: var(--black-background);
  z-index: 400;
  -webkit-box-shadow: 0px 2px 13px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 13px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 13px -6px rgba(0, 0, 0, 0.75);
}
.Toolbar span {
  margin-left: auto;
  color: var(--orange-background);
  margin-right: 20px;
}

.Toolbar button {
  filter: invert(58%) sepia(66%) saturate(4793%) hue-rotate(351deg)
    brightness(96%) contrast(91%);

  z-index: 400;
  outline: 0;
}
@media screen and (min-width: 769px) {
  .Toolbar button {
    display: none;
  }
}
